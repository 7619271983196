$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-color: white;
$hamburger-layer-border-radius: 0;

@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "src/scss/variables";

#hamburger-container {
  display: flex;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;

  &.is-active {
    background-color: $rangers-dark-blue;
  }
}

.menu-drawer {
  position: absolute;
  top: 100%;
  right: -1px;
  background-color: $rangers-dark-blue;
  border-right: $white-border-style;
  border-bottom: $white-border-style;
  border-left: $white-border-style;
  overflow: hidden;
  transition: 0.5s;
  visibility: hidden;

  .menu-item {
    margin: 1rem 3rem 1rem 1rem;
    font-size: 16px;
    color: white;
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      text-underline: white;
    }
  }
}